import React from 'react';
import { Link } from 'react-router-dom';
import { faGithub, faReddit, faFacebook, faPinterest, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Handle Match</h3>
          <p>Find and secure your ideal username across multiple social media platforms.</p>
          <div className="social-icons">
            <Link target='_blank' to="https://www.facebook.com/profile.php?id=61566910139695">
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link target='_blank' to="https://www.pinterest.com/handlematch">
              <FontAwesomeIcon icon={faPinterest} />
            </Link>
            <Link target='_blank' to="https://www.youtube.com/@handlematch">
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
            <Link target='_blank' to="https://github.com/handlematch">
              <FontAwesomeIcon icon={faGithub} />
            </Link>
            <Link target='_blank' to="https://www.reddit.com/user/handlematch/">
              <FontAwesomeIcon icon={faReddit} />
            </Link>
          </div>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact-us">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Resources</h3>
          <ul>
            <li><a href="/blog">Blog</a></li>
            <li><Link to="/term-of-service">Terms of Service</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Newsletter</h3>
          <p>Stay updated with our latest features and news.</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Enter your email" />
            <button type="submit" className="btn btn-primary">Subscribe</button>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Handle Match. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
