import React, { useState, useEffect, useMemo, useCallback, Suspense } from "react";
import axios from "axios";
import "../styles/home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faInstagram, faTwitter, faReddit, faFacebook, faPinterest, faYoutube, faTiktok, faQuora, faDiscord, faMedium, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Helmet from 'react-helmet';


const socialMediaPlatforms = [
  { name: "Instagram", url: "https://www.instagram.com/", visit: "https://www.instagram.com/", icon: faInstagram },
  { name: "Twitter", url: "https://twitter.com/", visit: "https://www.twitter.com/", icon: faTwitter },
  { name: "GitHub", url: "https://github.com/", visit: "https://www.github.com/", icon: faGithub },
  { name: "Reddit", url: "https://www.reddit.com/", visit: "https://www.reddit.com/user/", icon: faReddit },
  { name: "Facebook", url: "https://www.facebook.com/", visit: "https://www.facebook.com/", icon: faFacebook },
  { name: "Pinterest", url: "https://www.pinterest.com/", visit: "https://www.pinterest.com/", icon: faPinterest },
  { name: "YouTube", url: "https://www.youtube.com/", visit: "https://www.youtube.com/@", icon: faYoutube },
  { name: "TikTok", url: "https://www.TikTok.com/", visit: "https://www.TikTok.com/@", icon: faTiktok },
  { name: "Quora", url: "https://www.quora.com/", visit: "https://www.quora.com/profile/", icon: faQuora },
  { name: "Discord", url: "https://www.discord.com/", visit: "https://www.discord.com/invite/", icon: faDiscord },
  { name: "Medium", url: "https://www.medium.com/", visit: "https://www.medium.com/@", icon: faMedium },
  { name: "LinkedIn", url: "https://www.linkedin.com/", visit: "https://www.linkedin.com/in/", icon: faLinkedin },
];

const HandleMatch = () => {
  const [username, setUsername] = useState(""); // Stores the input username
  const [results, setResults] = useState([]);   // Stores the results from API
  const [loading, setLoading] = useState(false); // Tracks the loading state
  const [filter, setFilter] = useState("all");   // Filter state ("all", "available", "taken")
  const [sortOrder, setSortOrder] = useState("default"); // Sort order state
  const [isResultsVisible, setIsResultsVisible] = useState(false); // Controls results visibility
  const urlhost = process.env.REACT_APP_SRV_HOST_URL;

  // Function to check username availability for each platform
  const checkPlatformAvailability = useCallback(async (username, platform) => {
    try {
      const response = await axios.get(`${urlhost}/${platform.name.toLowerCase()}-user/${username}`);
      return response.data.exists;
    } catch (error) {
      console.error(`Error checking ${platform.name}:`, error);
      return false; 
    }
  }, [urlhost]);

  // Main function to check username availability across platforms
  const checkUsername = useCallback(async () => {
    if (!username) return; // Do nothing if username is empty

    setLoading(true);
    setIsResultsVisible(false);  // Hide results initially while loading

    const availabilityResults = [];

    // Iterate over each platform and check availability
    for (const platform of socialMediaPlatforms) {
      const isAvailable = await checkPlatformAvailability(username, platform);
      availabilityResults.push({
        platform: platform.name,
        available: isAvailable,
        url: isAvailable ? platform.url : platform.visit
      });
    }
    // Update the results state and make them visible
    setIsResultsVisible(true);  // Show results when finished
    setResults(availabilityResults);
    setLoading(false);
  }, [username, checkPlatformAvailability]);

  // Filter results based on availability status
  const filteredResults = useMemo(() =>
    results.filter((result) =>
      filter === "all" ? true : filter === "available" ? result.available : !result.available
    ), [results, filter]);

  // Sort results based on platform name (A-Z or Z-A)
  const sortedResults = useMemo(() => {
    return [...filteredResults].sort((a, b) => {
      if (sortOrder === "nameAsc") return a.platform.localeCompare(b.platform);
      if (sortOrder === "nameDesc") return b.platform.localeCompare(a.platform);
      return 0;
    });
  }, [filteredResults, sortOrder]);

  // Save results as CSV file
  const saveResults = useCallback(() => {
    let csv = 'Username,Platform,Url,Status\n';
    results.forEach(item => {
      const status = item.available ? 'Available' : 'Taken';
      csv += `${username},${item.platform},${item.url},${status}\n`;
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `[HandleMatch]_Results_${username}.csv`;
    link.click();
  }, [results, username]);

  // Loader animation when checking usernames
  useEffect(() => {
    if (loading) {
      const letters = document.querySelectorAll('.loader-text .letter');
      letters.forEach((letter, index) => {
        letter.style.animationDelay = `${index * 80}ms`;
        letter.classList.add('drop-in');
      });

      const interval = setInterval(() => {
        letters.forEach(letter => letter.classList.remove('drop-in'));
        setTimeout(() => {
          letters.forEach((letter, index) => {
            letter.style.animationDelay = `${index * 80}ms`;
            letter.classList.add('drop-in');
          });
        }, 100);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [loading]);


  return (
    <main>
      <Helmet>
        <title>Check Social Media Username Availability | Handle Match Tool</title>
        <meta name="description" content="Check social media username availability with Handle Match. Secure your brand name and export free reports to boost personal branding across platforms." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="Handle Match, social media username availability, check username availability, free username checker, secure social media usernames, username availability tool, free report export, personal branding tool, social media handle checker, brand name availability, instant username checker, username availability across platforms, social media platforms, personal branding, username search tool" />
        <meta property="og:title" content="Check Social Media Username Availability | Handle Match Tool" />
        <meta property="og:description" content="Check social media username availability with Handle Match. Secure your brand name and export free reports to boost personal branding across platforms." />
        <link rel="canonical" href="https://www.handlematch.com/" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Handle Match",
            "url": "https://www.handlematch.com/",
            "image": "https://www.handlematch.com/Images/HandleMatch_logo.webp",
            "sameAs": [
              "https://www.facebook.com/handlematch",
              "https://www.twitter.com/handlematch",
              "https://www.instagram.com/handlematch"
            ]
          })}
        </script>
      </Helmet>

      <div className="container">
        <h1>Handle Match</h1>
        <p className="description">Check username availability instantly across multiple social platforms. Secure your ideal username or brand name and maintain a strong online presence.</p>
        <div className="input-group">
          <i className="fa-solid fa-user input-icon"></i>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
          />
          <button onClick={checkUsername} disabled={loading}>
            {loading ? "Checking..." : "Check"}
          </button>
          {sortedResults.length !== 0 && (
            <button onClick={saveResults} data-tippy-content="Save results as CSV">Save Results</button>
          )}
        </div>

        {isResultsVisible && (
          <div className="controls show">
            <div className="filter-buttons">
              <button
                className={`filter-button ${filter === "all" ? "active" : ""}`}
                onClick={() => setFilter("all")}
              >
                All
              </button>
              <button
                className={`filter-button ${filter === "available" ? "active" : ""}`}
                onClick={() => setFilter("available")}
              >
                Available
              </button>
              <button
                className={`filter-button ${filter === "taken" ? "active" : ""}`}
                onClick={() => setFilter("taken")}
              >
                Taken
              </button>
            </div>

            <div className="sort-container">
              <span className="sort-label">Sort by:</span>
              <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                <option value="default">Default</option>
                <option value="nameAsc">Name A-Z</option>
                <option value="nameDesc">Name Z-A</option>
              </select>
            </div>
          </div>
        )}

        <div className={`${!loading ? "results" : ""}`}>
          {!loading ? (
            sortedResults.map((result, index) => (
              <div key={index} className={`result-item show ${result.available ? "available" : "taken"}`}>
                <FontAwesomeIcon icon={socialMediaPlatforms[index].icon} className={`status ${result.available ? "platform-icon-available" : "platform-icon-notavailable"}`} />
                <span>{result.platform}</span>
                <span className={`status ${result.available ? "available" : "taken"}`}>
                  {result.available ? (
                    <>
                      <FontAwesomeIcon className="icon-success" icon={faCheckCircle} /> Available
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon className="icon-error" icon={faTimesCircle} /> Taken
                    </>
                  )}
                </span>
                {result.available ? (
                  <a href={result.url} target="_blank" id="register-url" rel="noopener noreferrer">
                    Register
                  </a>
                ) : (
                  <a href={`${result.url}${username}`} id="visit-url" target="_blank" rel="noopener noreferrer">
                    Visit Profile
                  </a>
                )}
              </div>
            ))
          ) : (
            <div id="creative-loader" className="creative-loader">
              <div className="loader-content">
                <div className="loader-text">
                  {'Handle'.split('').map((char, index) => (
                    <span className="letter" key={`handle-${index}`}>
                      {char}
                    </span>
                  ))}
                  &nbsp;
                  {'Match'.split('').map((char, index) => (
                    <span className="letter" key={`match-${index}`}>
                      {char}
                    </span>
                  ))}
                </div>
                <div className="loader-icon">
                  <svg className="circular-loader" viewBox="25 25 50 50">
                    <circle className="loader-path" cx="50" cy="50" r="20" fill="none" strokeWidth="3" />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <section id="about">
        <Suspense fallback={<div>Loading FAQ...</div>}>
          <div className="faq-section faq-container">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-list">
              <div className="faq-item">
                <h3>What is Handle Match?</h3>
                <p>Handle Match is an advanced online tool designed to help individuals, businesses, and brands check username availability across multiple social media platforms. With just a few clicks, you can instantly see whether your desired username is available on popular platforms such as Instagram, Twitter, Facebook, and TikTok, among others. Handle Match simplifies the process of securing consistent branding across platforms, making it easier for you to maintain a unified online presence. Whether you're a new business or a personal brand, Handle Match ensures that you can reserve a unique username before someone else does.</p>
              </div>

              <div className="faq-item">
                <h3>How many platforms does Handle Match check?</h3>
                <p>Handle Match currently checks username availability on 12 of the most popular social media platforms. These include industry giants like Facebook, Twitter, Instagram, YouTube, TikTok, LinkedIn, and Pinterest, ensuring coverage across the most widely used networks. Our team is continually working to expand this list, so more platforms will be added soon. By checking across multiple platforms simultaneously, Handle Match saves you time and helps you stay ahead of the curve when securing your online presence.</p>
              </div>

              <div className="faq-item">
                <h3>Is there a limit to how many usernames I can check?</h3>
                <p>No, Handle Match does not impose a strict limit on the number of username searches you can perform. You are free to check as many usernames as you'd like to find the one that perfectly aligns with your brand or personal identity. However, we encourage users to be mindful and responsible when using our tool to ensure an optimal experience for everyone. Additionally, we are working on features that allow users to save their searches or generate reports to make it even easier to track available usernames for future use.</p>
              </div>

              <div className="faq-item">
                <h3>Can I reserve or claim usernames through Handle Match?</h3>
                <p>Handle Match is purely a username checking tool and does not have the capability to reserve or claim usernames on your behalf. While we provide the service of showing username availability, you will need to visit each social media platform individually to create accounts and secure your desired username. We recommend acting quickly if a username is available, as someone else may claim it if you delay. If you're starting a business or personal brand, securing consistent usernames across all platforms is a crucial step in building a cohesive online identity.</p>
              </div>

              <div className="faq-item">
                <h3>Why is having the same username on multiple platforms important?</h3>
                <p>Consistency across platforms is key to building a strong personal or business brand online. Having the same username across social media platforms not only makes it easier for your audience to find and recognize you, but it also establishes a sense of professionalism and credibility. Whether you're an influencer, a startup, or an established business, using the same username across all platforms ensures your brand identity remains clear and easily accessible. Handle Match simplifies this process by allowing you to check username availability on multiple platforms at once, helping you secure your brand identity quickly.</p>
              </div>

              <div className="faq-item">
                <h3>Does Handle Match provide a free report export?</h3>
                <p>Yes, Handle Match offers the option to export a free report after checking username availability. This feature is especially helpful for users who are comparing multiple username options or want to keep track of which usernames are still available across platforms. The report includes the platforms checked and the status of the username on each one, giving you a comprehensive view of your options. This feature is perfect for individuals or businesses planning their personal branding strategies.</p>
              </div>

              <div className="faq-item">
                <h3>How does Handle Match help with personal branding?</h3>
                <p>Personal branding is all about creating a consistent and memorable identity across the web. Handle Match assists in this by allowing you to secure the same username across different social media platforms, which is vital for brand recognition. Whether you're an entrepreneur, freelancer, or content creator, a unified username makes it easier for your audience to find you, remember you, and follow your work across multiple channels. By simplifying the process of checking username availability, Handle Match ensures you can focus on building your brand, rather than worrying about inconsistent handles or missing out on your preferred username.</p>
              </div>

              <div className="faq-item">
                <h3>Is Handle Match free to use?</h3>
                <p>Yes, Handle Match is a completely free tool. There are no hidden fees or subscriptions required to use the basic features of the platform, including checking username availability and exporting a report of your search results. This makes it an ideal tool for individuals, small businesses, and startups who are looking to establish their brand without breaking the bank. In the future, we may introduce additional premium features for users who want more advanced functionalities, but the core tool will remain free for everyone.</p>
              </div>

              <div className="faq-item">
                <h3>Can I use Handle Match for business branding?</h3>
                <p>Absolutely. Handle Match is perfect for businesses looking to establish or protect their brand identity across multiple social media platforms. By ensuring your business name or slogan is available as a username across platforms, you can build a cohesive online presence that is easy for customers to find and engage with. Many businesses struggle with inconsistent social media handles, which can lead to confusion and reduced brand recognition. With Handle Match, you can ensure that your business maintains a strong, unified digital identity across all major platforms.</p>
              </div>
            </div>
          </div>
        </Suspense>
      </section>
    </main>
  );
};

export default HandleMatch;
