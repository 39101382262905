import React from 'react';
import '../styles/privacypolicy.css';

const PrivacyPolicy = () => {
  return (
    <div class="container">
    <h1>Privacy Policy</h1>
    <p>Last updated: 10/05/2024</p>
    <p>Handle Match ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.</p>
    
    <h2>Information We Collect</h2>
    <p>We collect information that you provide directly to us, such as when you create an account, use our services, or contact us for support. This may include:</p>
    <ul>
        <li>Personal information (e.g., name, email address)</li>
        <li>Login credentials</li>
        <li>Usage data and preferences</li>
    </ul>

    <h2>How We Use Your Information</h2>
    <p>We use the information we collect to:</p>
    <ul>
        <li>Provide, maintain, and improve our services</li>
        <li>Communicate with you about our services</li>
        <li>Respond to your inquiries and support requests</li>
        <li>Analyze usage patterns and trends</li>
    </ul>

    <h2>Data Security</h2>
    <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>

    <h2>Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at [contact email].</p>
</div>
  );
};

export default PrivacyPolicy;
