import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import '../styles/blog.css';

// import articlesData from "../Helper/ArticlesBlogData.json"

const BlogPage = ({ articles }) => {
  const [currentPosts, setCurrentPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(6);
  const [activeCategory, setActiveCategory] = useState("all");
  const [query, setQuery] = useState("");
  const blogPosts = articles;
  

  useEffect(() => {
    filterPosts();
    // eslint-disable-next-line
  }, [activeCategory, query]);

  const renderBlogPost = (post) => (
    <article
      key={post.id}
      className="blog-card shadow-lg animate-fadeInUp hover-lift"
    >
      <img
        src={post.image}
        alt={post.title}
        className="w-full h-48 object-cover"
      />
      <div className="p-6">
        <span className="category-badge text-white px-3 py-1 !rounded-full text-xs font-semibold inline-block mb-2">
          {post.category.replace("-", " ")}
        </span>
        <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
        <p className="text-gray-600 mb-4">{post.content[0].text}</p>
        <div className="flex flex-wrap mb-4">
          {post.keywords.map((keyword) => (
            <span
              key={keyword}
              className="keyword"
              data-keyword={keyword}
              onClick={() => searchPosts(keyword)}
            >
              {keyword}
            </span>
          ))}
        </div>
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-500">
            {post.estimatedReadingMinutes}
          </span>
          <Link
            to={`/blog/${post.urltitle}`}
            className="text-purple-600 hover:text-purple-800 font-semibold"
          >
            <button>Read More</button>
          </Link>
        </div>
      </div>
    </article>
  );

  const filterPosts = () => {
    let filteredPosts = blogPosts;
    if (activeCategory !== "all") {
      filteredPosts = filteredPosts.filter(
        (post) => post.category === activeCategory
      );
    }
    if (query) {
      filteredPosts = filteredPosts.filter(
        (post) =>
          post.title.toLowerCase().includes(query.toLowerCase()) ||
          post.content[0].text.toLowerCase().includes(query.toLowerCase()) ||
          post.keywords.some((keyword) =>
            keyword.toLowerCase().includes(query.toLowerCase())
          )
      );
    }
    setCurrentPosts(filteredPosts.slice(0, visiblePosts));
  };

  const loadMorePosts = () => {
    setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 3);
  };

  const searchPosts = (keyword) => {
    setQuery(keyword);
  };


  return (
    <div className="overflow-x-hidden">
      {/* <Head
        page={"Blog"}
        description={
          "Use our YouTube Channel Audit Tool to assess the performance of your YouTube channel, including video titles, keywords, and descriptions, identifying areas for improvement, content truncate strategy optimization, competitor benchmarking, SEO enhancement, audience insights, progress tracking, and monetization optimization in order to enhance your video rankings and overall visibility."
        }
      /> */}
      <main className="container mx-auto px-6 py-24">
        <section className="mb-16 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 gradient-text floating-element">
          Handle Match Blog
          </h1>
          <p className="text-xl text-gray-600 mb-8">
          Stay updated with the latest trends and tips in social media username management
          </p>
          <div
            className="flex flex-wrap justify-center items-center gap-4 mb-8"
            id="categoryFilter"
          >
            <button
              className={`category-badge text-white px-4 py-2 rounded-full text-sm font-semibold hover-lift ${
                activeCategory === "all" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("all")}
            >
              All
            </button>
            <button
              className={`category-badge text-white px-4 py-2 rounded-full text-sm font-semibold hover-lift ${
                activeCategory === "username-strategy" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("username-strategy")}
            >
              Username Strategy
            </button>
            <button
              className={`category-badge text-white px-4 py-2 rounded-full text-sm font-semibold hover-lift ${
                activeCategory === "branding-strategy" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("branding-strategy")}
            >
              Branding Strategy
            </button>
            <button
              className={`category-badge text-white px-4 py-2 rounded-full text-sm font-semibold hover-lift ${
                activeCategory === "username-trends" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("username-trends")}
            >
              Username Trends
            </button>
            {/* <button
              className={`category-badge text-white px-4 py-2 rounded-full text-sm font-semibold hover-lift ${
                activeCategory === "monetizaton" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("monetizaton")}
            >
              Monetization
            </button>
            <button
              className={`category-badge text-white px-4 py-2 rounded-full text-sm font-semibold hover-lift ${
                activeCategory === "youtube-algorithm" ? "active" : ""
              }`}
              onClick={() => setActiveCategory("youtube-algorithm")}
            >
              YouTube Algorithm
            </button> */}
          </div>
          <form
            className="max-w-2xl mx-auto mb-8"
            onSubmit={(e) => {
              e.preventDefault();
              searchPosts(query);
            }}
          >
            <div className="relative">
              <input
                type="text"
                id="searchInput"
                placeholder="Search articles..."
                className="py-3 px-4 pr-12 !rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 search-input"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              {/* <button
                type="submit"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <i className="fas fa-search"></i>
              </button> */}
            </div>
          </form>
          <div
            id="keywordContainer"
            className="flex flex-wrap justify-center gap-2 mb-8"
          >
            {/* Render keywords dynamically if needed */}
          </div>
        </section>

        <section
          id="blogPosts"
          className="mb-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {currentPosts.map(renderBlogPost)}
        </section>

        <div className="mt-12 flex justify-center">
          <button
            id="loadMoreBtn"
            className="btn-primary px-6 py-3 rounded-full font-semibold hover-lift"
            onClick={loadMorePosts}
          >
            Load More
          </button>
        </div>
      </main>
    </div>
  );
};

export default BlogPage;