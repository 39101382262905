import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);
    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    // Get the current location (path) using React Router's useLocation hook
    const location = useLocation();

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                    <img src="/Images/HandleMatch_logo.png" alt="Handle Match Logo" width="60" height="60" />
                    <span className="logoname">Handle Match</span>
                </Link>

                <div className="menu-toggle" id="mobile-menu" onClick={toggleMenu}>
                    <span className={`bar ${menuActive ? 'active' : ''}`}></span>
                    <span className={`bar ${menuActive ? 'active' : ''}`}></span>
                    <span className={`bar ${menuActive ? 'active' : ''}`}></span>
                </div>

                <ul className={`navbar-menu ${menuActive ? 'active' : ''}`}>
                    <li>
                        <Link className={location.pathname === "/" ? "active" : ""} to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className={location.pathname === "/about" ? "active" : ""} to="/about">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link className={location.pathname.includes("/blog")   ? "active" : ""} to="/blog">
                            Blog
                        </Link>
                    </li>
                    <li>
                        <Link className={location.pathname === "/contact-us" ? "active" : ""} to="/contact-us">
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
