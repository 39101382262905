import React from 'react';
import '../styles/termofservice.css';

const TermOfService = () => {
  return (
    <div class="container">
        <h1>Terms of Service</h1>
        <p>Welcome to Handle Match. These Terms of Service govern your use of our website and services. By using Handle Match, you agree to these terms.</p>
        
        <h2>1. Use of Service</h2>
        <p>Handle Match provides a username availability checking service across various social media platforms. You agree to use this service only for lawful purposes and in accordance with these Terms.</p>
        
        <h2>2. User Responsibilities</h2>
        <ul>
            <li>You are responsible for maintaining the confidentiality of your account information.</li>
            <li>You agree not to use our service for any illegal or unauthorized purpose.</li>
            <li>You must not transmit any worms, viruses, or any code of a destructive nature.</li>
        </ul>
        
        <h2>3. Intellectual Property</h2>
        <p>The content, organization, graphics, design, and other matters related to Handle Match are protected under applicable copyrights and other proprietary laws. The copying, redistribution, use, or publication by you of any such matters or any part of the service is strictly prohibited.</p>
        
        <h2>4. Disclaimer of Warranties</h2>
        <p>Handle Match is provided on an 'as is' and 'as available' basis. We do not warrant that the service will be uninterrupted or error-free.</p>
        
        <h2>5. Limitation of Liability</h2>
        <p>In no event shall Handle Match be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.</p>
        
        <h2>6. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time. We will always post the most current version on our site. By continuing to use the service, you agree to be bound by the updated Terms.</p>
    </div>
  );
};

export default TermOfService;
