import React from 'react';
import '../styles/contactus.css';


const ContactUs = () => {
  return (
    <div class="contact-form container">
        <h1>Contact Us</h1>
        <p className="description">
        Get in touch with us for any questions or support.
        </p>
    <form>
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" id="name" name="name" required />
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" id="email" name="email" required />
      </div>
      <div class="form-group">
        <label for="message">Message</label>
        <textarea id="message" name="message" required></textarea>
      </div>
      <button type="submit" class="submit-btn">Send Message</button>
    </form>
  </div>
  );
};

export default ContactUs;
