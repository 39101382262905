import React from 'react';
import '../styles/aboutus.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt, faShare, faBoltLightning } from "@fortawesome/free-solid-svg-icons";  // Import solid icons


const AboutUs = () => {
  return (
    <div class="contact-form container">
        <h1>About Handle Match</h1>
        <p className="description">
        Handle Match is your go-to tool for effortlessly managing your online presence across multiple social media platforms. Our mission is to simplify the process of finding and securing the perfect username, helping you maintain a consistent brand identity in the digital world.
        </p>
        <div class="features">
      <div class="feature">
        <i>
        <FontAwesomeIcon className="feature-icon" icon={faBoltLightning} /> 
        </i>
        <h3>Instant Availability Check</h3>
        <p>Check username availability on 12+ popular platforms in seconds.</p>
      </div>
      <div class="feature">
        <i>
        <FontAwesomeIcon className="feature-icon" icon={faShieldAlt} /> 
        </i>
        <h3>Secure</h3>
        <p>Your searches are private and secure, ensuring your digital identity is protected.</p>
      </div>
      <div class="feature">
        <i>
        <FontAwesomeIcon className="feature-icon" icon={faShare} /> 
        </i>
        <h3>Extract & Share Your Findings</h3>
        <p>Extract and Share available usernames directly from the platform.</p>
      </div>
    </div>
    <div class="tool-description">
      <h2>What Handle Match Offers</h2>
      <p>Handle Match is your ultimate solution for managing your online presence across multiple social media platforms. Our tool provides:</p>
      <ul>
        <li>Instant username availability checks on 12+ popular platforms</li>
        <li>Intelligent username suggestions based on your input</li>
        <li>Comprehensive reports on username availability and existing profiles</li>
        <li>Easy sharing of your findings with just one click</li>
      </ul>

      <h3>Advantages of Using Handle Match</h3>
      <ul>
        <li>Save time by checking multiple platforms simultaneously</li>
        <li>Maintain a consistent brand identity across social media</li>
        <li>Discover alternative usernames when your preferred choice is taken</li>
        <li>Make informed decisions about your online presence</li>
        <li>Stay ahead of the competition by securing your ideal username</li>
      </ul>
    </div>
  </div>
  );
};

export default AboutUs;
