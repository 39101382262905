import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import TermOfService from './pages/TermOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer';
import BlogPage from "./pages/BlogPage";
import ArticlePage from "./pages/ArticlePage";
import articlesData from "./components/ArticlesBlogData.json"

function App() {
  const { articles } = articlesData;
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element= {<BlogPage articles={articles} />} />
        <Route path="/blog/:urltitle" element={<ArticlePage articles={articles}  />} />
        <Route path="/article" element={<ArticlePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/term-of-service" element={<TermOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
