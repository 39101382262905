import React from 'react';
import { useParams } from "react-router-dom";
import '../styles/articlepage.css'; // Import the CSS for this page

const ArticlePage = ({ articles }) => {
    const { urltitle } = useParams();
    const article = articles.find((post) => post.urltitle === urltitle);
  return (
    <div className="container">
      <a href="/blog" className="return-to-blog">← Return to Blog</a>
      {article && (
      <main>
        <article>
          <img
            src={article?.image}
            alt="Choosing the perfect username"
            className="featured-image"
          />
          <h1>{article?.title}</h1>
          <div className="article-meta">
            <img
              src="https://i.ibb.co/th5wMkt/profile-picture-1728ecf2bd.png"
              alt="Author avatar"
            />
            <span>By Lena McCaney | {article?.estimatedReadingMinutes}</span>
          </div>
          {/* <div className="article-content">
            <p>
              Choosing the perfect username is crucial for establishing your
              online identity. Whether you're creating accounts for social
              media, professional networking, or online communities, your
              username is often the first impression you make. In this article,
              we'll explore the best practices for selecting a username that
              represents your brand across all social media platforms.
            </p>

            <h2>1. Keep it Simple and Memorable</h2>
            <p>
              Your username should be easy to remember and type. Avoid using
              complex combinations of numbers and special characters. Instead,
              opt for something simple, catchy, and relevant to your brand or
              personality.
            </p>

            <h2>2. Make it Consistent Across Platforms</h2>
            <p>
              Using the same username across different platforms helps in
              building a cohesive online presence. It makes it easier for people
              to find and recognize you across various social media sites.
            </p>

            <h2>3. Consider Your Professional Image</h2>
            <p>
              If you're using social media for professional purposes, choose a
              username that reflects your professional identity. Using your real
              name or a variation of it can be a good option for maintaining a
              professional image.
            </p>

            <h2>4. Be Creative, But Clear</h2>
            <p>
              While creativity can help you stand out, make sure your username
              is still clear and understandable. Avoid using l33t speak or
              complex wordplay that might confuse your audience.
            </p>

            <h2>5. Future-Proof Your Choice</h2>
            <p>
              Choose a username that will still be relevant and appropriate in
              the future. Avoid references to current trends or personal details
              that might change over time.
            </p>

            <p>
              Remember, your username is an important part of your online brand.
              Take the time to choose wisely, and use tools like Handle Match to
              ensure your preferred username is available across multiple
              platforms.
            </p>
          </div> */}
           <div className="article-content">
              {article.content.map((section, index) => (
                <div key={index}>
                  {section.type === "section" && (
                    <div>
                      <h2 id={`${section.type}${index}`}>{section.title}</h2>
                      <p>{section.text}</p>
                    </div>
                  )}

                  {section.type === "image" && (
                    <div className="text-center py-4"> 
                      <img src={section.url} alt={section.caption} style={{width:"100%", height:700}} />
                      <p className="py-4">{section.caption}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
        </article>
      </main>
      )}
    </div>
  );
};

export default ArticlePage;
